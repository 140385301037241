import React from "react";
import { Button } from "@/components/ui/button";
import { ArrowRightIcon } from "./icons";
import { Link } from "gatsby";

const FeatureCard = ({ Icon, title, description, link }) => (
  <Link to={link}>
    <div className="rounded-lg bg-gradient-to-tr from-sky-700 to-lime-600 hover:from-lime-600 hover:to-sky-700 transition-all p-6 shadow-lg">
      <div className="flex items-center justify-between">
        <Icon className="h-8 w-8 text-white" />
        <Button className="text-white hover:text-blue-500" size="icon" variant="ghost">
            <ArrowRightIcon className="h-5 w-5" />
        </Button>
      </div>
      <h3 className="mt-4 text-lg font-bold text-white">{title}</h3>
      <p className="mt-2 text-gray-100">{description}</p>
    </div>
  </Link>
);

export default FeatureCard;
