import React from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "@/components/ui/sheet"
  import { CalendarRange } from "lucide-react";
  import useGoogleSheetData from "@/components/useGoogleSheet";

  export default function KeyDates() {

    const { data: data, loading, error } = useGoogleSheetData(
        "12C7gJKgvnIc6Qe3ppHzymt-87TQdT5Ykq1PlIcv51u0",
        "Key Dates"
    );

    return (
        <>
            <Sheet>
                <SheetTrigger className="fixed bottom-5 left-5 rounded-lg py-3 px-3 text-xl font-bold drop-shadow-lg hover:drop-shadow-xl flex items-center gap-2 uppercase bg-primary text-white z-50"><CalendarRange />Key Dates</SheetTrigger>
                <SheetContent side={"left"}>
                    <SheetHeader>
                        <SheetTitle className="mb-0 pb-0 mt-0 text-2xl font-bold">Key Dates</SheetTitle>
                        <SheetDescription className="flex flex-col gap-6">
                        {error && <div>Error: {error.message}</div>}
                            {!loading && !error && data[0].data.map((item, index) => (
                            <div key={index}>
                                <h3 className="text-xl text-primary mt-0">{item.title}</h3>
                                <p className="text-base font-semibold text-black border-l-4 pl-2">{item.date}</p>
                            </div>
                        ))}
                        </SheetDescription>
                    </SheetHeader>
                </SheetContent>
            </Sheet>
        </>
    )
}