export const menuItems = {

    // header menu items
    header: [
      {
        label: "Home/About",
        to: "",
        dropdown: true,
        items: [
          { label: "Home", to: "/" },
          { label: "Welcome Message", to: "/#welcome" },
          // { label: "Promotional Video", to: "/#promo-video" },
          { label: "Host & Committee", to: "/host-and-committee" },
          { label: "Latest News", to: "/latest-news" },
        ],
      },
      {
        label: "Registration",
        to: "",
        dropdown: true,
        items: [
          { label: "Registration", to: "/registration" },
        ],
      },
      {
        label: "Destination",
        to: "",
        dropdown: true,
        items: [
          { label: "Conference Venue", to: "/conference-venue" },
          { label: "Discover Auckland", to: "/discover-auckland" },
          { label: "Explore New Zealand", to: "/explore-new-zealand" },
          { label: "Tiaki Promise", to: "/tiaki-promise" },
        ],
      },
      {
        label: "Sponsorship",
        to: "",
        dropdown: true,
        items: [
          { label: "Sponsorship Opportunities", to: "/sponsorship-opportunities" },
          // { label: "Sponsors & Exhibitors", to: "/sponsors-and-exhibitors" },
        ],
      },
      {
        label: "Information",
        to: "",
        dropdown: true,
        items: [
          { label: "Visa Information", to: "/visa-information" },
          { label: "Privacy Policy", to: "/privacy-policy" },
          { label: "Contact Us", to: "/contact" },
        ],
      },
    ],

    // footer menu items
    footer: [
      {
        label: "Home/About",
        items: [
          { label: "Home", to: "/" },
          { label: "Welcome Message", to: "/#welcome" },
          // { label: "Promotional Video", to: "/#promo-video" },
          { label: "Host & Committee", to: "/host-and-committee" },
          { label: "Latest News", to: "/latest-news" },
        ],
      },
      {
        label: "Registration",
        items: [
          { label: "Registration", to: "/registration" },
        ],
      },
      {
        label: "Destination",
        items: [
          { label: "Conference Venue", to: "/conference-venue" },
          { label: "Discover Auckland", to: "/discover-auckland" },
          { label: "Explore New Zealand", to: "/explore-new-zealand" },
          { label: "Tiaki Promise", to: "/tiaki-promise" },
        ],
      },
      {
        label: "Sponsorship",
        to: "",
        dropdown: true,
        items: [
          { label: "Sponsorship Opportunities", to: "/sponsorship-opportunities" },
          // { label: "Sponsors & Exhibitors", to: "/sponsors-and-exhibitors" },
        ],
      },
      {
        label: "Information",
        items: [
          { label: "Visa Information", to: "/visa-information" },
          { label: "Privacy Policy", to: "/privacy-policy" },
          { label: "Contact Us", to: "/contact" },
        ],
      },
    ],
  };
  