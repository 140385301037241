import React from "react";
import { useLocation } from "@reach/router";
import { Users, ClipboardCheck, MapPin, Telescope } from "lucide-react";
import FeatureCard from "./FeatureCard";
import FWNavBar from "./navbar/FullWidthNavBar";
import { Link } from "gatsby";
import UserForm from '@/components/form/EOI-Form';
import { getPageData } from '../data/pageData';

const Header = () => {
  const location = useLocation();
  const { backgroundImage, heading, description } = getPageData(location.pathname);
  
  const isHomePage = location.pathname === "/";

  let featureSection;

  const eventName = 'TIAFT 2025'; // Enter the event name (Once set, do not change!)
  const eventDate = '2024-07-03'; // Dynamically set event date in YYYY-MM-DD format

  if (isHomePage) {
    featureSection = (
      <section className="py-12 md:py-20 relative z-10">
        <div className="container mx-auto grid grid-cols-1 gap-6 px-6 sm:grid-cols-2 md:grid-cols-4">
            <FeatureCard
                Icon={Users}
                title="Host & Committee"
                description="Meet the dedicated hosts and committee members of TIAFT 2025."
                link="/host-and-committee/"
            />
            <FeatureCard
                Icon={ClipboardCheck}
                title="Registration"
                description="Register now to secure your spot at TIAFT 2025."
                link="/registration/"
            />
            <FeatureCard
                Icon={MapPin}
                title="Conference Venue"
                description="Discover the prestigious venue for TIAFT 2025."
                link="/conference-venue/"
            />
            <FeatureCard
                Icon={Telescope}
                title="Discover Auckland"
                description="Explore the vibrant city of Auckland during the conference."
                link="/discover-auckland/"
            />
        </div>
    </section>
  );
}

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-70"></div>
        <FWNavBar />
        {/* <InLineNavBar /> */}

        <section className="relative z-10 py-10 lg:py-20">
          <div className="container mx-auto grid grid-cols-1 gap-12 px-6 md:grid-cols-2">
            <div className="space-y-6">
              <Link to="/">
                <img
                  src="/TIAFT2025-logo-horiz-rev.svg"
                  alt="TIAFT 2025 Logo"
                  width={300}
                />
              </Link>
              <h1 className="text-4xl font-bold leading-tight text-secondary sm:text-5xl md:text-6xl">
                {heading}
              </h1>
              <p className="text-white text-lg">
                {description}
              </p>
              {isHomePage ? (
              <p className="text-white text-3xl font-bold">
                Express your interest by filling in the form today!
              </p>
              ) : (null)}
            </div>
            <div className="relative rounded-lg dark-theme">
              <div>
              {isHomePage ? (
                <UserForm eventName={eventName} eventDate={eventDate} />
              ) : (null)}
              </div>
            </div>
          </div>
        </section>

        {featureSection}

      </header>
      
    </>
  );
}

export default Header;
